.topWindow {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index:9999; /* Чтобы приклеенный элемент располагался над всеми остальными элементами страницы */
    }
.question{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index:9999; 
    margin-right: 1vh;
    margin-bottom: 1vh;
    border: 1px solid #44444c;
}