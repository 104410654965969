@import url(https://fonts.googleapis.com/css?family=Josefin+Sans|Open+Sans&display=swap);
.container-fluid {
    padding: 10px 20px;
    background-color: #FFCC00;
}

.font-weight-bold {
    color: #2b2b2f;
}

h1 {
    color: #2b2b2f;
}

.btn {
    padding: 10px 20px;
}
.btn-yellow-outline{
    background-color: #FFCC00;
    border-color: #2b2b2f;
}
.btn-yellow-outline:hover{
    background-color: #faf7dc;
}
.btn-yellow{
    background-color: #faf7dc;
    border-color: #faf7dc;
}
.btn-yellow:hover{
    font-size: 1.2vw;
}

.btn:hover a {
    color: #44444c;
}

a {
    color: #2b2b2f;
}

@media screen and (max-device-width: 1025px) {
    h1, p, h3, a {
        font-size: 4vmin;
    }
    .row {
        margin-right: 15px;
        margin-left: -20px;
    }
    .btn{
        padding: 10px;
    }
}
@media screen and (max-device-width: 1025px) { 
    .card-text {
        font-size: 3vmin;
        line-height: 1em;
        text-align: left;
    }
    .photoLine{
        padding-bottom: 10vmin;
    }
    .card-img-overlay{
        width: 70%;
    }
    .container, .container-sm {
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
}

.card{
    background: none;
    border: none;
    color: #2b2b2f;
    text-align: center;
    padding: 20px 0;
}
.card-img-overlay{
        width: 60%;
    }


.photoLine{
    background-color: #faf7dc;
}


/*анимация гамбургера*/
.navbar-toggler.active  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='grey' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8l20 16M4 24L24 8'/%3E%3C/svg%3E");
}

.nav-link{
    font-weight: 700;
    font-size: larger;
}
.nav{
    background-color: #ffcc00;
    padding: 0.5em;
    margin-bottom: 4vw;
}
.nav-item:hover{
    
    border-radius: 6px;
}
.nav-link:hover{
    color: black;
}
.dropdown:hover > .dropdown-menu {
    display: block; 
    margin: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-color: #ffcc00;
   } 
.dropdown-menu{
    background-color: #faf7dc;   
    padding: 0.5em;
}
.dropdown:hover > .nav-link {
   color: black; 
}
.dropdown-item{
    padding-left: 3em;
}
.dropdown-item:hover{
    background-color: #ffcc00;
}
.rounded-circle {
    background-color: #faf7dc;
    width: 300px;
    height: 300px;
    color: #2b2b2f;
    border: 1px solid #ffcc00;
    box-shadow: 2px 2px 3px #2b2b2f;
}

.interesting {
    color: #2b2b2f;
    background-color: #faf7dc;
    padding: 3em 0;
    
}

h2 {
    color: #2b2b2f !important;
    font-family: 'Josefin Sans', sans-serif;
    
}

h2::after {
    content: "";
    width: 30% !important;
    position: absolute;
    margin: 0 auto;
    height: 2px !important;
    background: #ffcc00 !important;
    left: 0;
    right: 0;
    bottom: -10px;
}

@media screen and (max-device-width: 1025px) {
    .rounded-circle {
        width: 150px;
        height: 150px;
    }
}
.myBottom {
    background-color: #ffcc00;
    padding: 2em 0;
    color: #2b2b2f;
}

.myBottom .tel:hover {
    color: #faf7dc;
    text-shadow: 1px 1px 1px #44444c;
}

@media screen and (max-device-width: 1025px) {
    li, h2, label, .form-control {
        font-size: 4vmin;
    }
    .tel {
        font-size: 4vmin;
        padding: 0;
    }
    .review {
        font-size: 4vmin;
    }
}
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
    padding: 10px;
    margin: 2em;
   background-color: transparent;

}
.card-img-left, .card-img-right{
    border-radius: 1em;
   background-color: white;
}
.card-link{
    float: right;
    float: inline-end;
}
.card-link:hover{
    text-decoration: underline;
}
.breadcrumb{
    background: transparent;
}
.breadcrumb-item, .breadcrumb-item > a{
    color: #44444c;
}
.topWindow {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index:9999; /* Чтобы приклеенный элемент располагался над всеми остальными элементами страницы */
    }
.question{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index:9999; 
    margin-right: 1vh;
    margin-bottom: 1vh;
    border: 1px solid #44444c;
}
*{
    margin: 0;
    padding: 0;
}
.contact-page{
    color: #2b2b2f; 
    padding: 3em;
}
h2{
    color: #2b2b2f;
    text-shadow: 1px 1px 1px #44444c;
}

.adres{
    color: #2b2b2f;
}
.contact-jumbotron{
    background-color: #faf7dc;
    border: 3px outset #ffcc00;
    color: #2b2b2f;
}
.tel{
    padding-left: 2em;
}
.tel:hover{
    color: #ffcc00;
    text-shadow: 1px 1px 1px #44444c;
}
.inst{
    width: 7vh;
}
.inst:hover{
    transform: rotate(37deg);
}
@media screen and (max-device-width: 1025px) { 
  li {
      font-size: 4vmin;
      line-height: 4vh;
  }
  .tel{
    font-size: 4vmin;
    line-height: 4vh;
    padding: 0;
  }
  .contact-page{
      padding: 1rem;
  }

}
*{
    margin: 0;
    padding: 0;
}
@media screen and (max-device-width: 1025px) { 

    .certificates{
      font-size: 4vmin;
      padding: 0;
    }
  
  }
.biography-page{
    color: #2b2b2f;
    padding: 3em;
    font-size: 1.2rem;
}

.certificates{
    color: #2b2b2f;
}
.certificates:hover{
    color: #2b2b2f;
}

* {
    margin: 0;
    padding: 0;
}

.photo-page {

    color: #2b2b2f;
    padding: 3em;
    padding-bottom: 50%;
}
.PhotoGrid__imageWrapper___1YTK9 {
    border: 3px solid #ffcc00;
}

*{
    margin: 0;
    padding: 0;
}

.list{
    list-style: decimal;
}
.scientific-page{
    background-color: #faf7dc;
    color: #2b2b2f;
    padding: 3em;
    padding-bottom: 80%;
}
*{
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    padding: 0;
}

.photo-page {
    color: #44444c;
    padding: 3em;
    padding-bottom: 80%;
}


*{
    margin: 0;
    padding: 0;
}
.biography-page{
    color: #2b2b2f;
    padding: 3em;
}

*{
    margin: 0;
    padding: 0;
}

*{
    margin: 0;
    padding: 0;
}
.biography-page{
    color: #2b2b2f;
    padding: 3em;
}
p{
    line-height: 1.6rem;
}

.commView{

    color: #2b2b2f;
    padding: 3em;
}
.commViewcont{
background-color: #faf7dc;
padding: 2vw 7vw;
border-radius: 2vw;
}
h2{
	position: relative;
}
h2::after{
	content: "";
	width: 130px;
	position: absolute;
	margin: 0 auto;
	height: 3px;
	background: #2b2b2f;
	left: 0;
	right: 0;
	bottom: -10px;
}
.carousel-inner .carousel-item .img-box {
	width: 135px;
	height: 135px;
}
.d-block{
    width: 130px;
	height: 130px;
}
.carousel-control-prev{
	left: -100px;

}
.carousel-control-next{
	right: -100px;
	
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232b2b2f' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232b2b2f' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;}
@media screen and (max-device-width: 1025px) { 
    strong,h6,p{
        font-size: 4vmin;
	}
	.carousel-control-prev{
		left: -40px;
	}
	.carousel-control-next{
		right: -40px;
	}
	.commView{
		padding: 1em;
	}
  }

